<template>
  <v-dialog
    v-model="dialog"
    :class="
      $vuetify?.display?.lgAndUp
        ? 'align-center justify-center startNewInspectionLarge'
        : 'align-center justify-center startNewInspectionSmall'
    "
    persistent
    no-click-animation
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Start New Inspection</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="startNewInspectionForm" v-model="validStartNewInspection">
          <v-row>
            <v-col cols="12" class="pt-0 pb-4 startNewInspectionDatePicker">
              <LabelDatefield
                id="inspection_Start_Date"
                label="B.IE.02 Inspection Start Date"
                v-model="inspectionStartDate"
                class="pt-1"
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                isRequired
                :isEditable="true"
                :maxDate="new Date()"
              ></LabelDatefield>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0 pb-4 startNewInspectionDatePicker">
              <LabelDatefield
                id="inspection_End_Date"
                label="B.IE.03 Inspection End Date"
                v-model="inspectionEndDate"
                class="pt-1"
                :minDate="
                  inspectionStartDate ? inspectionStartDate : new Date()
                "
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                isRequired
                :isEditable="true"
              ></LabelDatefield>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-4 pb-4">
              <LabelSelect
                id="sel_Team_Leader"
                labelXl="6"
                labelLg="6"
                labelMd="6"
                labelSm="6"
                selectXl="6"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                isRequired
                label="B.IE.04 NCBI (Team Leader)"
                :options="teamLeaders"
                :isEditable="true"
                v-model="teamLeader"
              ></LabelSelect>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-4">
              <v-row>
                <v-col cols="6" class="bold pt-2"
                  ><v-label class="bold d-flex"
                    ><span>Inspection Types Performed:</span></v-label
                  ></v-col
                >
                <v-col cols="6" class="pt-1">
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="initial"
                        label="1 - Initial"
                        :showColon="false"
                        :isEditable="true"
                        v-model="initial"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="routine"
                        label="2 - Routine"
                        :showColon="false"
                        :isEditable="true"
                        v-model="routine"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="underwater"
                        label="3 - Underwater"
                        :showColon="false"
                        :isEditable="true"
                        v-model="underwater"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="nstm"
                        label="4 - NSTM"
                        :showColon="false"
                        :isEditable="true"
                        v-model="nstm"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="damage"
                        label="5 - Damage"
                        :showColon="false"
                        :isEditable="true"
                        v-model="damage"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="inDepth"
                        label="6 - In-Depth"
                        :showColon="false"
                        :isEditable="true"
                        v-model="inDepth"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="special"
                        label="7 - Special"
                        :showColon="false"
                        :isEditable="true"
                        v-model="special"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="service"
                        label="8 - Service"
                        :showColon="false"
                        :isEditable="true"
                        v-model="service"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="scourMonitoring"
                        label="9 - Scour Monitoring"
                        :showColon="false"
                        :isEditable="true"
                        v-model="scourMonitoring"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="problemArea"
                        label="P - Problem Area"
                        :showColon="false"
                        :isEditable="true"
                        v-model="problemArea"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="element"
                        label="E - Element"
                        :showColon="false"
                        :isEditable="true"
                        v-model="element"
                        @update:modelValue="updateSelections()"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"
                      ><LabelCheckbox
                        :labelXl="7"
                        :labelLg="7"
                        :labelMd="9"
                        :labelSm="9"
                        id="qa"
                        label="Q - QA"
                        :showColon="false"
                        v-model="qa"
                        @update:modelValue="updateSelections()"
                        :indent="false"
                      ></LabelCheckbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="btn_Ok"
          variant="outlined"
          class="mx-5"
          text
          :disabled="
            !validStartNewInspection ||
            (inspectionTypesPerformed.includes('4') &&
              inspectionTypesPerformed.length == 1) ||
            inspectionTypesPerformed.length == 0 ||
            (inspectionTypesPerformed.includes('1') &&
              inspectionTypesPerformed.includes('2'))
          "
          @click="newInspectionHandler"
        >
          Ok
        </v-btn>
        <v-btn
          id="btn_Cancel"
          variant="outlined"
          class="mx-5"
          text
          @click="cancel"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, nextTick, computed, watch } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { useConfigStore } from "@/stores/config";
import LabelDatefield from "@/components/common/LabelDatefield.vue";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import { getIN15Value } from "@/composables/keyFields.js";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import inspectionType from "@/data/inspectionType.js";
import { LOGGER } from "@/util/logger";
import {
  INSPECTION_TYPE_PERFORMED,
  CHECKBOX_VALUE,
} from "@/constants/Inspections";
import { clone } from "@/util/clone";

export default {
  name: "StartNewInspection",
  emits: ["startNewInspection"],

  setup(_, ctx) {
    const inspectionStore = useInspectionStore();
    const configStore = useConfigStore();
    let structure = computed(() => inspectionStore.selectedInspection);
    let startNewInspectionForm = ref();
    let validStartNewInspection = ref(true);
    let dialog = ref(false);
    const nstmSelected = computed(() => nstm.value == "1");
    let teamLeaders = computed(() =>
      configStore.getTeamLeaders(structure.value, nstmSelected.value)
    );
    let inspectionStartDate = ref(null);
    let inspectionEndDate = ref(null);
    let teamLeader = ref(null);
    let inspectionTypesPerformed = ref([]);
    let initial = ref("0");
    let nstm = ref("0");
    let underwater = ref("0");
    let routine = ref("0");
    let damage = ref("0");
    let inDepth = ref("0");
    let special = ref("0");
    let service = ref("0");
    let scourMonitoring = ref("0");
    let problemArea = ref("0");
    let element = ref("0");
    let qa = ref("0");

    const cancel = () => {
      closeDialog();
    };

    const closeDialog = () => {
      dialog.value = false;
    };

    const openDialog = () => {
      inspectionTypesPerformed.value = [];
      dialog.value = true;
      inspectionStartDate.value = null;
      inspectionEndDate.value = null;
      teamLeader.value = null;
      initial.value = "0";
      routine.value = "0";
      underwater.value = "0";
      nstm.value = "0";
      damage.value = "0";
      inDepth.value = "0";
      special.value = "0";
      service.value = "0";
      scourMonitoring.value = "0";
      problemArea.value = "0";
      element.value = "0";
      qa.value = "0";
      nextTick(() => {
        startNewInspectionForm.value.validate();
      });
    };
    function setInspEvnt(obj) {
      for (let i in obj) {
        if (obj[i] instanceof Array) {
          setInspEvnt(obj[i]);
        } else if (obj[i] && Object.hasOwn(obj[i], "INSPKEY")) {
          obj[i].INSPKEY = structure.value.InspEvnt.INSPKEY;
        }
      }
    }
    const overallScourHolesChangedByUser = computed(() => {
      return inspectionStore.getOverallScourHolesChangedByUser;
    });
    const overallScourHoles = computed({
      get() {
        if (
          !structure.value.UserInsp.SCOUR_COND_RATING &&
          !overallScourHolesChangedByUser.value
        ) {
          if (structure.value?.T_Underwater_Insp[0] != null) {
            let overallScourHoles =
              structure.value.T_Underwater_Insp[0].SCOUR_HOLE;
            for (let idx in structure.value?.T_Underwater_Insp) {
              if (
                parseInt(structure.value.T_Underwater_Insp[idx].SCOUR_HOLE) <
                parseInt(overallScourHoles)
              ) {
                overallScourHoles =
                  structure.value.T_Underwater_Insp[idx].SCOUR_HOLE;
              }
            }
            return overallScourHoles;
          } else {
            return "";
          }
        } else {
          return structure.value.UserInsp.SCOUR_COND_RATING;
        }
      },
    });
    const copyKeyFields = () => {
      let { InspEvnt, UserInsp, T_Bearings, T_Joints, T_Underwater_Insp } =
        structure.value;
      structure.value.keyFields = {
        inspectionDate: InspEvnt.INSPDATE,
        inspectionType: InspEvnt.INSPTYPE,
        inspectionStatus: InspEvnt.INSPSTAT,
        apprAlign: InspEvnt.APPRALIGN,
        apprRoadCondRate: UserInsp.APPR_ROAD_COND_RATE,
        apprSlabCondRate: UserInsp.APPR_SLAB_COND_RATE,
        bearingsOverallCondRating: T_Bearings?.[0]?.BEARING_OVERALL_COND_RATING,
        brInspectionFreq: InspEvnt.BRINSPFREQ,
        chanRating: InspEvnt.CHANRATING,
        chanProtRating: UserInsp.CHAN_PROT_RATING,
        condition: InspEvnt.CONDITION,
        culvRating: InspEvnt.CULVRATING,
        dkRating: InspEvnt.DKRATING,
        deckWsCondRate: UserInsp.DECK_WS_COND_RATE,
        jointsOverallCondRating: T_Joints?.[0]?.OVERALL_COND_RATING,
        elInspectionDone: InspEvnt.ELINSPDONE,
        elInspectionFreq: InspEvnt.ELINSPFREQ,
        elInspectionReq: InspEvnt.ELINSPREQ,
        fcInspectionDone: InspEvnt.FCINSPDONE,
        fcInspectionFreq: InspEvnt.FCINSPFREQ,
        fcInspectionReq: InspEvnt.FCINSPREQ,
        nbInspectionDone: InspEvnt.NBINSPDONE,
        nstmCondition: UserInsp.NSTM_CONDITION,
        osInspectionDone: InspEvnt.OSINSPDONE,
        osInspectionFreq: InspEvnt.OSINSPFREQ,
        osInspectionReq: InspEvnt.OSINSPREQ,
        overallScourHoles: overallScourHoles.value,
        railRating: InspEvnt.RAILRATING,
        scourCondRating: UserInsp.SCOUR_COND_RATING,
        scourCritical: InspEvnt.SCOURCRIT,
        scourCriticalCat: InspEvnt.SCOUR_CRIT_CTGRY,
        streamBedMaterial: [getIN15Value(T_Underwater_Insp)],
        subRating: InspEvnt.SUBRATING,
        supRating: InspEvnt.SUPRATING,
        transRating: InspEvnt.TRANSRATIN,
        underwaterRating: UserInsp.UNDERWATER_RATING,
        uwInspectionDone: InspEvnt.UWINSPDONE,
        uwInspectionFreq: InspEvnt.UWINSPFREQv,
        uwInspectionReq: InspEvnt.UWINSPREQ,
        wateradeq: InspEvnt.WATERADEQ,
      };
    };
    const setComments = () => {
      const commentTypes = ["479", "67", "79", "478"];
      const T_Insp_Comment_Prev = [];
      const T_Insp_Comment = structure.value.T_Insp_Comment;

      commentTypes.forEach((type) => {
        const index = T_Insp_Comment.findIndex((a) => a.COMMENT_TYPE === type);
        if (index !== -1) {
          T_Insp_Comment_Prev.push(T_Insp_Comment[index]);
          T_Insp_Comment.splice(index, 1);
        }
      });

      structure.value.T_Insp_Comment_Prev = T_Insp_Comment_Prev;
    };

    const updateDurations = (inspectionDate, lastInspectionDate) => {
      let gapInDays =
        Math.abs(inspectionDate - lastInspectionDate) / (1000 * 60 * 60 * 24);
      let gapInYears = gapInDays / 365;

      for (let prop in structure.value.T_Insp_Condition_Hist) {
        if (prop.endsWith("_DURATION")) {
          let currentDuration = structure.value.T_Insp_Condition_Hist[prop];
          let newDuration = currentDuration + gapInYears;
          structure.value.T_Insp_Condition_Hist[prop] =
            Math.round(newDuration * 10) / 10;
        }
      }
    };

    let organizations = computed(() => configStore.getOrganizations);

    let webUsers = computed(() => configStore.getWebUsers);

    const updateInspectionOrganizationName = () => {
      let bpId = webUsers.value.find(
        (a) => parseInt(a.webUser.userId) == structure.value.InspEvnt.INSPUSRKEY
      )?.webUser?.bpId;
      if (bpId == null) {
        structure.value.UserInsp.INSP_ORG_NAME = "Penndot";
      } else {
        structure.value.UserInsp.INSP_ORG_NAME = organizations.value.find(
          (a) => parseInt(a.organization.bpId) == bpId
        )?.organization?.bpName;
      }
    };

    const newInspectionHandler = () => {
      startNewInspectionForm.value
        .validate()
        .then(async () => {
          if (validStartNewInspection.value) {
            inspectionStore.setOverallScourHolesChangedByUser(false);
            copyKeyFields();
            structure.value.InspEvnt.INSPSTAT = "1";
            const lastInspectionDate = structure.value.InspEvnt.INSPDATE;
            updateDurations(
              inspectionStartDate.value,
              new Date(lastInspectionDate)
            );
            structure.value.InspEvnt.INSPDATE =
              inspectionStartDate.value.toISOString();
            structure.value.UserInsp.OVERALL_END_DATE =
              inspectionEndDate.value.toISOString();
            const nextInspNumber =
              parseInt(structure.value.InspEvnt.INSPKEY) + 1;
            structure.value.InspEvnt.INSPKEY = nextInspNumber
              .toString()
              .padStart(4, "0");
            structure.value.T_Bearings?.forEach(
              (bearing) => (bearing.ID = null)
            );
            structure.value.T_Joints?.forEach((joint) => (joint.ID = null));
            structure.value.T_Bearings_Details.forEach(
              (bearing) => (bearing.ID = null)
            );
            structure.value.T_Joints_Details?.forEach(
              (joint) => (joint.ID = null)
            );

            structure.value.InspEvnt.INSPUSRKEY = teamLeader.value;
            updateInspectionOrganizationName();
            if (
              structure.value.UserInsp.RECOMMEND_RATING_REVIEW == "1" &&
              ["3", "6"].includes(structure.value.UserInsp.LR_REVIEW_ACTION)
            ) {
              structure.value.UserInsp.LR_REVIEW_ACTION = "1";
            }

            structure.value.UserInsp.NEW_WS = "0";
            setComments();

            if (
              ["1", "3", "6"].includes(
                structure.value.UserInsp.LR_REVIEW_ACTION
              )
            ) {
              structure.value.UserInsp.RECOMMEND_RATING_REVIEW = "0";
            }
            structure.value.UserInsp.POST_REVIEW_REC = "0";
            if (structure.value.T_Scour_Comp != null) {
              structure.value.T_Scour_Comp.SCOUR_CALCULATED = "0";
            }
            setInspEvnt(structure.value);
            structure.value.Snbi_Insp_Schedule[0].INSPKEY =
              structure.value.InspEvnt.INSPKEY;
            structure.value.InspEvnt.INSPTYPE = handleSetPrimaryInspType();
            handleInspCondHistory();
            //set snbi_schedule intervals to 0 if -1 or blank.
            handleSnbiScheduleInterval();
            handleInspectionTypesPerformed();
            updateMaxEndDate();
            handleLegacyInspectionInterval();
            closeDialog();
            ctx.emit("startNewInspection");
          }
        })
        .catch((err) => LOGGER.logException(err));
    };
    const updateSelections = () => {
      inspectionTypesPerformed.value = [];
      if (initial.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(INSPECTION_TYPE_PERFORMED.INITIAL);
      }
      if (routine.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(INSPECTION_TYPE_PERFORMED.ROUTINE);
      }
      if (underwater.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(
          INSPECTION_TYPE_PERFORMED.UNDERWATER
        );
      }
      if (nstm.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(INSPECTION_TYPE_PERFORMED.NSTM);
      }
      if (damage.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(INSPECTION_TYPE_PERFORMED.DAMAGE);
      }
      if (inDepth.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(INSPECTION_TYPE_PERFORMED.IN_DEPTH);
      }
      if (special.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(INSPECTION_TYPE_PERFORMED.SPECIAL);
      }
      if (service.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(INSPECTION_TYPE_PERFORMED.SERVICE);
      }
      if (scourMonitoring.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(
          INSPECTION_TYPE_PERFORMED.SCOUR_MONITORING
        );
      }
      if (problemArea.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(
          INSPECTION_TYPE_PERFORMED.PROBLEM_AREA
        );
      }
      if (element.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(INSPECTION_TYPE_PERFORMED.ELEMENT);
      }
      if (qa.value == CHECKBOX_VALUE.CHECKED) {
        inspectionTypesPerformed.value.push(INSPECTION_TYPE_PERFORMED.QA);
      }
    };
    const handleSetPrimaryInspType = () => {
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.ROUTINE
        ) &&
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.UNDERWATER
        )
      ) {
        return "W";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.INITIAL
        )
      ) {
        return "F";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.ROUTINE
        )
      ) {
        return "R";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.SPECIAL
        )
      ) {
        return "I";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.UNDERWATER
        )
      ) {
        return "U";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.DAMAGE
        )
      ) {
        return "B";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.IN_DEPTH
        )
      ) {
        return "D";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.SCOUR_MONITORING
        )
      ) {
        return "P";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.PROBLEM_AREA
        )
      ) {
        return "P";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.ELEMENT
        )
      ) {
        return "E";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.SERVICE
        )
      ) {
        return "H";
      }
      if (
        inspectionTypesPerformed.value.includes(INSPECTION_TYPE_PERFORMED.QA)
      ) {
        return "M";
      }
    };
    const setRBM = (inSpecificReportGroup, snbiInspEventObject, inspType) => {
      if (
        inSpecificReportGroup &&
        [
          INSPECTION_TYPE_PERFORMED.ROUTINE,
          INSPECTION_TYPE_PERFORMED.UNDERWATER,
          INSPECTION_TYPE_PERFORMED.NSTM,
        ].includes(inspType)
      ) {
        snbiInspEventObject.RBM = "1";
      } else if (inSpecificReportGroup) {
        snbiInspEventObject.RBM = "N";
      }
      return snbiInspEventObject;
    };
    const handleInspectionTypesPerformed = () => {
      structure.value.InspEvnt.NBINSPDONE = "0";
      structure.value.InspEvnt.UWINSPDONE = "0";
      structure.value.InspEvnt.FCINSPDONE = "0";
      structure.value.InspEvnt.OSINSPDONE = "0";
      structure.value.InspEvnt.ELINSPDONE = "0";
      structure.value.Snbi_Insp_Event = [];
      let snbiInspEventMainObject = clone(inspectionType);
      let inSpecificReportGroup = false;
      if (["S1", "A1", "L1"].includes(structure.value.Bridge.REPORTGROUP)) {
        inSpecificReportGroup = true;
      } else {
        snbiInspEventMainObject.RBM = "N";
      }
      snbiInspEventMainObject.BRKEY = structure.value.Bridge.BRKEY;
      snbiInspEventMainObject.INSPKEY = structure.value.InspEvnt.INSPKEY;
      snbiInspEventMainObject.START_DATE = inspectionStartDate.value;
      snbiInspEventMainObject.END_DATE = inspectionEndDate.value;
      snbiInspEventMainObject.TEAM_LEADER = teamLeader.value;
      snbiInspEventMainObject.MOD_DATE = new Date().toISOString();
      setInspectionTypesPerformed(
        snbiInspEventMainObject,
        inSpecificReportGroup
      );
    };

    const setInspectionTypesPerformed = (
      snbiInspEventMainObject,
      inSpecificReportGroup
    ) => {
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.INITIAL
        )
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);
        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.INITIAL
        );
        snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.INITIAL;
        snbiInspEventObject.INSP_INTERVAL = 0;
        snbiInspEventObject.DUE_DATE = null;
        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
        structure.value.InspEvnt.NBINSPDONE = "1";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.ROUTINE
        )
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);
        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.ROUTINE
        );
        snbiInspEventObject = handleRoutine(snbiInspEventObject);
        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
        structure.value.InspEvnt.NBINSPDONE = "1";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.UNDERWATER
        )
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);
        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.UNDERWATER
        );

        snbiInspEventObject = handleUnderwater(snbiInspEventObject);
        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
        structure.value.InspEvnt.UWINSPDONE = "1";
      }
      if (
        inspectionTypesPerformed.value.includes(INSPECTION_TYPE_PERFORMED.NSTM)
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);

        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.NSTM
        );
        snbiInspEventObject = handleNSTM(snbiInspEventObject);
        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
        structure.value.InspEvnt.FCINSPDONE = "1";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.DAMAGE
        )
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);

        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.DAMAGE
        );
        snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.DAMAGE;
        snbiInspEventObject.INSP_INTERVAL = 0;
        snbiInspEventObject.DUE_DATE = null;
        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.IN_DEPTH
        )
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);

        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.IN_DEPTH
        );
        snbiInspEventObject = handleInDepth(snbiInspEventObject);
        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.SPECIAL
        )
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);

        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.SPECIAL
        );
        snbiInspEventObject = handleOtherSpecial(snbiInspEventObject);

        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
        structure.value.InspEvnt.OSINSPDONE = "1";
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.SERVICE
        )
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);

        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.SERVICE
        );
        snbiInspEventObject = handleService(snbiInspEventObject);
        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.SCOUR_MONITORING
        )
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);

        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.SCOUR_MONITORING
        );
        snbiInspEventObject.INSP_TYPE =
          INSPECTION_TYPE_PERFORMED.SCOUR_MONITORING;
        snbiInspEventObject.INSP_INTERVAL = 0;
        snbiInspEventObject.DUE_DATE = null;
        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.PROBLEM_AREA
        )
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);

        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.PROBLEM_AREA
        );
        snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.PROBLEM_AREA;
        snbiInspEventObject.INSP_INTERVAL = 0;
        snbiInspEventObject.DUE_DATE = null;
        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
      }
      if (
        inspectionTypesPerformed.value.includes(
          INSPECTION_TYPE_PERFORMED.ELEMENT
        )
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);

        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.ELEMENT
        );
        snbiInspEventObject = handleElement(snbiInspEventObject);

        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
        structure.value.InspEvnt.ELINSPDONE = "1";
      }
      if (
        inspectionTypesPerformed.value.includes(INSPECTION_TYPE_PERFORMED.QA)
      ) {
        let snbiInspEventObject = clone(snbiInspEventMainObject);

        snbiInspEventObject = setRBM(
          inSpecificReportGroup,
          snbiInspEventObject,
          INSPECTION_TYPE_PERFORMED.QA
        );
        snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.QA;
        snbiInspEventObject.INSP_INTERVAL = 0;
        snbiInspEventObject.DUE_DATE = null;
        structure.value.Snbi_Insp_Event.push(snbiInspEventObject);
      }
    };

    const handleInspCondHistory = () => {
      if (!structure.value.T_Insp_Condition_Hist) {
        structure.value.T_Insp_Condition_Hist = {
          BRKEY: structure.value.Bridge.BRKEY,
          INSPKEY: structure.value.InspEvnt.INSPKEY,
          AENDRATING: null,
          AEBDRATING: null,
          AENDRATING_DURATION: 0.0,
          AEBDRATING_DURATION: 0.0,
          APPR_ROAD_COND_RATE: null,
          APPR_ROADWAY_DURATION: 0.0,
          APPR_SLAB_COND_RATE: null,
          APPR_SLAB_DURATION: 0.0,
          APPRALIGN: null,
          APPRALIGN_DURATION: 0.0,
          ARAILRATIN: null,
          ARAILRATIN_DURATION: 0.0,
          BRGCOND_DURATION: 0.0,
          CHANRATE_DURATION: 0.0,
          CHANRATING: null,
          CONDITION: null,
          CULV_DURATION: 0.0,
          CULVRATING: null,
          DECK_WS_COND_RATE: null,
          DECK_WS_SURF_DURATION: 0.0,
          DECKGEOM: null,
          DECKGEOM_DURATION: 0.0,
          DK_DURATION: 0.0,
          DKRATING: null,
          INSPDATE: structure.value.InspEvnt.INSPDATE,
          INSPTYPE: structure.value.InspEvnt.INSPTYPE,
          PAINT_COND_RATE: null,
          PAINT_EXT_DURATION: 0.0,
          PAINT_EXTENT_RATE: null,
          PANIT_DURATION: 0.0,
          RAILRATING: null,
          RAILRATING_DURATION: 0.0,
          SCOURCRIT: null,
          SCOURCRIT_DURATION: 0.0,
          SUB_DURATION: 0.0,
          SUBRATING: null,
          SUP_DURATION: 0.0,
          SUPRATING: null,
          TRANSRATIN: null,
          TRANSRATIN_DURATION: 0.0,
          UNDERCLR: null,
          UNDERCLR_DURATION: 0.0,
          WADEQ_DURATION: 0.0,
          WATERADEQ: null,
          SCOUTCRIT_DURATION_CHANGES: 1,
          CULV_DURATION_CHANGES: 1,
          SUB_DURATION_CHANGES: 1,
          SUP_DURATION_CHANGES: 1,
          DK_DURATION_CHANGES: 1,
          DECK_WS_SURF_DURATION_CHANGES: 1,
          APPRALIGN_DURATION_CHANGES: 1,
          APPR_SLAB_DURATION_CHANGES: 1,
          APPR_ROADWAY_CHANGES: 1,
          RAILRATING_DURATION_CHANGES: 1,
          TRANSRATIN_DURATION_CHANGES: 1,
          ARAILRATIN_DURATION_CHANGES: 1,
          AEBDRATING_DURATION_CHANGES: 1,
          DECKGEOM_DURATION_CHANGES: 1,
          UNDERCLR_DURATION_CHANGES: 1,
          PANIT_DURATION_CHANGES: 1,
          PAINT_EXT_DURATION_CHANGES: 1,
        };
      } else {
        structure.value.T_Insp_Condition_Hist.SCOUTCRIT_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.CULV_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.SUB_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.SUP_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.DK_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.APPRALIGN_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.DECKGEOM_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.UNDERCLR_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES = 0;
        structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES = 0;
      }
    };

    const handleRoutine = (snbiInspEventObject) => {
      snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.ROUTINE;
      snbiInspEventObject.INSP_INTERVAL =
        structure.value?.Snbi_Insp_Schedule[0]?.ROU_INTERVAL || 0;
      //set 7A60, 7A10
      snbiInspEventObject.DUE_DATE =
        structure.value.Snbi_Insp_Schedule[0].ROU_NEXT_DATE =
        structure.value.InspEvnt.NEXTINSP =
          setInspTypeDueDate(snbiInspEventObject);
      return snbiInspEventObject;
    };
    const handleUnderwater = (snbiInspEventObject) => {
      snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.UNDERWATER;
      snbiInspEventObject.INSP_INTERVAL =
        structure.value?.Snbi_Insp_Schedule[0]?.UW_INTERVAL || 0;
      snbiInspEventObject.DUE_DATE =
        structure.value.Snbi_Insp_Schedule[0].UW_NEXT_DATE =
        structure.value.InspEvnt.UWNEXTDATE =
          setInspTypeDueDate(snbiInspEventObject);
      return snbiInspEventObject;
    };
    const handleNSTM = (snbiInspEventObject) => {
      snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.NSTM;
      snbiInspEventObject.INSP_INTERVAL =
        structure.value?.Snbi_Insp_Schedule[0]?.NSTM_INTERVAL || 0;
      snbiInspEventObject.DUE_DATE =
        structure.value.Snbi_Insp_Schedule[0].NSTM_NEXT_DATE =
        structure.value.InspEvnt.FCNEXTDATE =
          setInspTypeDueDate(snbiInspEventObject);
      return snbiInspEventObject;
    };
    const handleInDepth = (snbiInspEventObject) => {
      snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.IN_DEPTH;
      snbiInspEventObject.INSP_INTERVAL =
        structure.value?.Snbi_Insp_Schedule[0]?.IND_INTERVAL || 0;
      snbiInspEventObject.DUE_DATE =
        structure.value.Snbi_Insp_Schedule[0].IND_NEXT_DATE =
          setInspTypeDueDate(snbiInspEventObject);
      return snbiInspEventObject;
    };
    const handleOtherSpecial = (snbiInspEventObject) => {
      snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.SPECIAL;
      snbiInspEventObject.INSP_INTERVAL =
        structure.value?.Snbi_Insp_Schedule[0]?.OS_INTERVAL || 0;
      snbiInspEventObject.DUE_DATE =
        structure.value.Snbi_Insp_Schedule[0].OS_NEXT_DATE =
        structure.value.InspEvnt.OSNEXTDATE =
          setInspTypeDueDate(snbiInspEventObject);
      return snbiInspEventObject;
    };
    const handleService = (snbiInspEventObject) => {
      snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.SERVICE;
      snbiInspEventObject.INSP_INTERVAL =
        structure.value?.Snbi_Insp_Schedule[0]?.SERV_INTERVAL || 0;
      snbiInspEventObject.DUE_DATE =
        structure.value.Snbi_Insp_Schedule[0].SERV_NEXT_DATE =
          setInspTypeDueDate(snbiInspEventObject);
      return snbiInspEventObject;
    };
    const handleElement = (snbiInspEventObject) => {
      snbiInspEventObject.INSP_TYPE = INSPECTION_TYPE_PERFORMED.ELEMENT;
      snbiInspEventObject.INSP_INTERVAL =
        structure.value?.Snbi_Insp_Schedule[0]?.ELEM_INTERVAL || 0;
      snbiInspEventObject.DUE_DATE =
        structure.value.Snbi_Insp_Schedule[0].ELEM_NEXT_DATE =
        structure.value.InspEvnt.ELNEXTDATE =
          setInspTypeDueDate(snbiInspEventObject);
      return snbiInspEventObject;
    };
    const updateMaxEndDate = () => {
      structure.value.UserInsp.OVERALL_END_DATE = new Date(
        Math.max(
          ...structure.value.Snbi_Insp_Event.map((i) => new Date(i.END_DATE))
        )
      ).toISOString();
    };
    const handleSnbiScheduleInterval = () => {
      let futureSchedule = structure.value.Snbi_Insp_Schedule?.[0];
      Object.keys(futureSchedule)?.forEach((key) => {
        if (key.endsWith("_INTERVAL")) {
          futureSchedule[key] = getInterval(futureSchedule[key]);
        }
      });
    };
    const handleLegacyInspectionInterval = () => {
      structure.value.InspEvnt.BRINSPFREQ = getInterval(
        structure.value?.InspEvnt?.BRINSPFREQ
      );
      structure.value.InspEvnt.FCINSPFREQ = getInterval(
        structure.value?.InspEvnt?.FCINSPFREQ
      );
      structure.value.InspEvnt.UWINSPFREQ = getInterval(
        structure.value?.InspEvnt?.UWINSPFREQ
      );
      structure.value.InspEvnt.OSINSPFREQ = getInterval(
        structure.value?.InspEvnt?.OSINSPFREQ
      );
      structure.value.InspEvnt.ELINSPFREQ = getInterval(
        structure.value?.InspEvnt?.ELINSPFREQ
      );
    };

    const getInterval = (value) => {
      return value == -1 || !value ? 0 : value;
    };

    const setInspTypeDueDate = (detail) => {
      if (
        detail.END_DATE &&
        detail.INSP_INTERVAL > 0 &&
        !["Z", "1", "5", "9", "P", "Q"].includes(detail.INSP_TYPE)
      ) {
        let endDateValue = new Date(detail.END_DATE);
        let day = endDateValue.getDate();
        const calculatedDueDate = new Date(
          endDateValue.setMonth(endDateValue.getMonth() + detail.INSP_INTERVAL)
        );
        if (calculatedDueDate.getDate() !== day) {
          //we crossed the month boundary
          calculatedDueDate.setDate(0); //set to the last day of previous month
        }
        return calculatedDueDate.toISOString();
      } else {
        return null;
      }
    };

    watch(
      () => [nstm.value],
      () => {
        if (nstm.value) {
          //check if the selected team leader is in the nstm team leaders with certs list
          if (
            !teamLeaders.value?.some(
              (leader) => teamLeader.value == leader?.value
            )
          ) {
            //if not in the list set the selected value to null
            teamLeader.value = null;
          }
        }
        startNewInspectionForm.value.validate();
      },
      { deep: true }
    );

    return {
      updateSelections,
      inspectionTypesPerformed,
      element,
      initial,
      routine,
      damage,
      inDepth,
      special,
      service,
      scourMonitoring,
      problemArea,
      qa,
      underwater,
      nstm,
      teamLeader,
      inspectionEndDate,
      inspectionStartDate,
      validStartNewInspection,
      startNewInspectionForm,
      teamLeaders,
      REFERENCE_TABLE,
      dialog,
      openDialog,
      structure,
      open,
      newInspectionHandler,
      cancel,
      closeDialog,
    };
  },
  components: {
    LabelSelect,
    LabelCheckbox,
    LabelDatefield,
  },
};
</script>
