<template>
  <v-row>
    <ExpandCollapseToggle
      id="deckPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel style="margin-top: 20px">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Deck Conditions</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="valid" ref="inspectionDeckForm">
            <v-row>
              <v-col xl="8" lg="8" md="12" sm="12" cols="12">
                <LabelSelect
                  labelXl="6"
                  labelLg="7"
                  labelMd="7"
                  labelSm="7"
                  selectXl="5"
                  selectLg="4"
                  selectMd="5"
                  selectSm="5"
                  id="deckCondRating"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.DK_DURATION,
                      structure?.T_Insp_Condition_Hist?.DK_DURATION_CHANGES
                    )
                  "
                  label="1A01 Deck Condition Rating"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      deckConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="structure.InspEvnt.DKRATING"
                ></LabelSelect
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <CommentTypeTextField
                  label="Overall Deck Notes"
                  id="field_Overall_Deck_Notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.OVERALL_DECK_COMMENTS
                  "
                  :readonly="!isEditable"
                  :counter="commentsMaxLength"
                  :maxlength="commentsMaxLength"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="8" lg="8" md="12" sm="12" cols="12">
                <LabelSelect
                  labelXl="6"
                  labelLg="7"
                  labelMd="7"
                  labelSm="7"
                  selectXl="5"
                  selectLg="4"
                  selectMd="5"
                  selectSm="5"
                  id="surfaceCondRating"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.DECK_WS_SURF_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.DECK_WS_SURF_DURATION_CHANGES
                    )
                  "
                  label="6B40 Wearing Surface Condition Rating"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      surfaceConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="structure.UserInsp.DECK_WS_COND_RATE"
                ></LabelSelect></v-col
            ></v-row>

            <v-row>
              <v-col cols="12">
                <CommentTypeTextField
                  label="Overall Wearing Surface Notes"
                  id="field_Overall_Wearing_Surface_Notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.OVERALL_WEARING_SURFACE_COMMENTS
                  "
                  :readonly="!isEditable"
                  :counter="commentsMaxLength"
                  :maxlength="commentsMaxLength"
                />
              </v-col>
            </v-row>
            <v-row
              ><v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="4"
                  textMd="5"
                  textSm="5"
                  id="chlorideContent"
                  :isEditable="isEditable"
                  label="6B10/6B11 Estimated Chloride Content/Date"
                  v-model="structure.UserInsp.EST_CHLORIDE_CONTENT_PCT"
                  @change="updateDate()"
                  appendWith="%"
                  type="number"
                  textStyle="width:90%"
                  maxlength="3"
                  :rules="
                    isEditable
                      ? [
                          (value) => {
                            return lessThanValueRule(value, 100);
                          },
                        ]
                      : []
                  "
                >
                </LabelText>
              </v-col>
              <v-col xl="4" lg="3" md="4" sm="4" cols="4" align-self="center">
                <LabelDatefield
                  textXl="11"
                  textLg="10"
                  textMd="10"
                  textSm="10"
                  textStyle="width:90%"
                  id="date_chloride_Date"
                  v-model="structure.UserInsp.EST_CHLORIDE_CONTENT_DATE"
                  :isEditable="isEditable"
                >
                </LabelDatefield>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelSelect
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  selectXl="6"
                  selectLg="4"
                  selectMd="6"
                  selectSm="4"
                  id="surfaceTypeMain"
                  label="5B02/6A30 Surface Type Main/Approach"
                  :refTable="REFERENCE_TABLE.DECK_SURFACE_TYPE"
                  v-model="structure.Bridge.DKSURFTYPE"
                ></LabelSelect> </v-col
              ><v-col xl="4" lg="3" md="4" sm="4" cols="3" align-self="center">
                <LabelSelect
                  selectXl="11"
                  selectLg="10"
                  selectMd="10"
                  selectSm="10"
                  id="surfaceTypeApproach"
                  label=""
                  :refTable="REFERENCE_TABLE.DECK_SURFACE_TYPE"
                  v-model="structure.UserBrdg.APPR_DKSURFTYPE"
                ></LabelSelect
              ></v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelSelect
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  selectXl="6"
                  selectLg="4"
                  selectMd="6"
                  selectSm="4"
                  id="membraneTypeMain"
                  label="5B03/6A31 Membrane Type Main/Approach"
                  :refTable="REFERENCE_TABLE.DECK_MEMBRANE_TYPE"
                  v-model="structure.Bridge.DKMEMBTYPE"
                ></LabelSelect></v-col
              ><v-col xl="4" lg="3" md="4" sm="4" cols="3" align-self="center">
                <LabelSelect
                  selectXl="11"
                  selectLg="10"
                  selectMd="10"
                  selectSm="10"
                  id="membraneTypeApproach"
                  label=""
                  :refTable="REFERENCE_TABLE.DECK_MEMBRANE_TYPE"
                  v-model="structure.UserBrdg.APPR_DKMEMBTYPE"
                ></LabelSelect
              ></v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelSelect
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  selectXl="6"
                  selectLg="4"
                  selectMd="6"
                  selectSm="4"
                  id="protectionMain"
                  label="5B04/6A32 Protection Main/Approach"
                  :refTable="REFERENCE_TABLE.DECK_PROTECTION_TYPE"
                  v-model="structure.Bridge.DKPROTECT"
                ></LabelSelect></v-col
              ><v-col xl="4" lg="3" md="4" sm="4" cols="3" align-self="center">
                <LabelSelect
                  selectXl="11"
                  selectLg="10"
                  selectMd="10"
                  selectSm="10"
                  id="protectionApproach"
                  label=""
                  :refTable="REFERENCE_TABLE.DECK_PROTECTION_TYPE"
                  v-model="structure.UserBrdg.APPR_DKPROTECT"
                ></LabelSelect
              ></v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="4"
                  textMd="6"
                  textSm="5"
                  id="WearingSurfaceThicknessMain"
                  :isEditable="isEditable"
                  label="6A33 Wearing Surface Thickness Main/Approach"
                  v-model="structure.UserBrdg.MAIN_WS_THICKNESS"
                  @update:modelValue="updateMainRecordedDate"
                  appendWith=" in"
                  type="number"
                  textStyle="width:90%"
                  maxlength="4"
                  decimalPlaces="1"
                  :pairCode="PAIR_CODE.MILLIMETERS_TO_INCHES"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                  :rules="
                    isEditable
                      ? [
                          (value) => {
                            return lessThanValueRule(
                              value,
                              DECK_MAX_VALUE.WEARING_SURFACE_THICKNESS
                            );
                          },
                        ]
                      : []
                  "
                >
                </LabelText></v-col
              ><v-col xl="4" lg="3" md="4" sm="4" cols="4" align-self="center">
                <LabelText
                  id="WearingSurfaceThicknessApproach"
                  :isEditable="isEditable"
                  v-model="structure.UserBrdg.APPR_WS_THICKNESS"
                  @update:modelValue="updateApproachRecordedDate"
                  appendWith=" in"
                  textXl="11"
                  textLg="10"
                  textMd="10"
                  textSm="10"
                  type="number"
                  maxlength="4"
                  textStyle="width:90%"
                  decimalPlaces="1"
                  :pairCode="PAIR_CODE.MILLIMETERS_TO_INCHES"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                  :rules="
                    isEditable
                      ? [
                          (value) => {
                            return lessThanValueRule(
                              value,
                              DECK_MAX_VALUE.WEARING_SURFACE_THICKNESS
                            );
                          },
                        ]
                      : []
                  "
                >
                </LabelText>
              </v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelDatefield
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="4"
                  textMd="6"
                  textSm="5"
                  textStyle="width:90%"
                  id="WearingSurfaceRecordedDateMain"
                  label="6A34 Wearing Surface Thickness Date Recorded Main/Approach"
                  v-model="structure.UserBrdg.MAIN_WS_THICK_DATE"
                  :isEditable="isEditable"
                >
                </LabelDatefield>
              </v-col>
              <v-col xl="4" lg="3" md="4" sm="4" cols="4" align-self="center">
                <LabelDatefield
                  textXl="11"
                  textLg="10"
                  textMd="10"
                  textSm="10"
                  textStyle="width:90%"
                  id="WearingSurfaceRecordedDateApproach"
                  v-model="structure.UserBrdg.APPR_WS_THICK_DATE"
                  :isEditable="isEditable"
                >
                </LabelDatefield>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  id="crackingMetric"
                  :isEditable="isEditable"
                  label="6B47 Deck Cracking Metric"
                  v-model="structure.UserInsp.DECK_CRACKING_METRIC"
                  appendWith=" YD/SY"
                  textStyle="width:90%"
                  :decimalPlaces="2"
                  type="number"
                  :rules="
                    isEditable ? [(greaterThanZeroRule, lessThanOneRule)] : []
                  "
                  maxlength="4"
                >
                </LabelText>
              </v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  id="maxBridgeHeight"
                  :isEditable="isEditable"
                  :isRequired="isEditable && isRequiredByGroup"
                  label="B.G.13 Maximum Bridge Height"
                  v-model="structure.UserBrdg.MAX_STRUCTURE_HEIGHT"
                  appendWith=" ft"
                  textStyle="width:80%"
                  type="number"
                  maxlength="4"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                  :roundingFactor="ROUNDING_FACTOR.ROUND"
                >
                </LabelText>
              </v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelSelect
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="3"
                  selectMd="4"
                  selectSm="5"
                  id="deckStrucType"
                  label="5B01 Deck Structure Type"
                  :refTable="REFERENCE_TABLE.DECK_STRUCTURE_TYPE"
                  v-model="structure.Bridge.DKSTRUCTYP"
                ></LabelSelect>
              </v-col>
            </v-row>
            <v-row
              ><v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  id="deckWidth"
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  label="5B07 Deck Width (O/O)"
                  v-model="structure.Bridge.DECKWIDTH"
                  appendWith=" ft"
                  :decimalPlaces="1"
                  type="number"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                >
                </LabelText></v-col
            ></v-row>
            <v-row
              ><v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  id="roadwayWidth"
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  label="5C27 Roadway Width (C/C)"
                  v-model="roadwayWidth"
                  appendWith=" ft"
                  :decimalPlaces="1"
                  type="number"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                >
                </LabelText></v-col
            ></v-row>
            <v-row
              ><v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  id="leftSidewalkWidth"
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  label="5B05 Left Curb or Sidewalk Width"
                  v-model="structure.Bridge.LFTCURBSW"
                  appendWith=" ft"
                  textStyle="width:80%"
                  :decimalPlaces="1"
                  type="number"
                  :maxlength="5"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                >
                </LabelText></v-col
            ></v-row>
            <v-row
              ><v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  id="rightSidewalkWidth"
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  label="5B06 Right Curb or Sidewalk Width"
                  v-model="structure.Bridge.RTCURBSW"
                  appendWith=" ft"
                  textStyle="width:80%"
                  :decimalPlaces="1"
                  type="number"
                  :maxlength="5"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                >
                </LabelText></v-col
            ></v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelSelect
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="3"
                  selectMd="4"
                  selectSm="5"
                  id="deckStrucType"
                  label="5B08 Bridge Median Type"
                  :options="bridgeMedianTypes"
                  v-model="structure.Bridge.BRIDGEMED"
                ></LabelSelect>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />
          <h3>Deck Inspection Notes</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col sm="12">
              <CommentTypeTextField
                label="Deck Top and Wearing Surface"
                id="field_Deck_Top_Wearing_Surface"
                :inspectionCommentType="
                  INSPECTION_COMMENTS.DECK_TOP_WEARING_SURFACE
                "
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12">
              <CommentTypeTextField
                label="Deck Underside"
                id="field_Deck_Underside"
                :inspectionCommentType="INSPECTION_COMMENTS.DECK_UNDERSIDE"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12">
              <CommentTypeTextField
                label="Deck Drainage"
                id="field_Deck_Drainage"
                :inspectionCommentType="INSPECTION_COMMENTS.DECK_DRAINAGE"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
import { ref, computed, watch, onMounted } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { getHistDuration } from "@/composables/util";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import useDateField from "@/composables/dateField.js";
import {
  requiredRule,
  greaterThanZeroRule,
  lessThanOneRule,
  lessThanValueRule,
} from "@/composables/validationRules";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { DECK_MAX_VALUE } from "@/constants/InspectionConstants";
import LabelDatefield from "@/components/common/LabelDatefield.vue";
import useDeckData from "@/composables/deckData";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionDeck",
  setup() {
    const allPanels = [0, 1];
    const panel = ref(allPanels);
    const inspectionStore = useInspectionStore();
    let deckConditionUpdated = ref(false);
    let surfaceConditionUpdated = ref(false);
    let conditionUpdated = ref(false);
    let structure = computed(() => inspectionStore.selectedInspection);
    let valid = ref(true);
    let inspectionDeckForm = ref(null);

    const { getFormattedDateStringNoTime } = useDateField();

    const configStore = useConfigStore();

    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    const { getData } = useDeckData();
    const [roadwayWidth] = getData.value;

    if (!isEditable.value) {
      panel.value = allPanels;
    }

    onMounted(async () => {
      await validate();
    });

    async function validate() {
      await inspectionDeckForm.value.validate();
    }

    const conditionRatings = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.CONDITION_RATING)
    );

    const deckSurfaceTypes = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.DECK_SURFACE_TYPE)
    );

    const deckMembraneTypes = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.DECK_MEMBRANE_TYPE)
    );

    const deckProtectionTypes = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.DECK_PROTECTION_TYPE)
    );

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    const updateDuration = () => {
      if (!structure.value.T_Insp_Condition_Hist) {
        structure.value.T_Insp_Condition_Hist = {};
      }
      if (surfaceConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION,
            structure.value.T_Insp_Condition_Hist.DECK_WS_SURF_DURATION_CHANGES
          );
      }

      if (deckConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.DK_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.DK_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.DK_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.DK_DURATION,
            structure.value.T_Insp_Condition_Hist.DK_DURATION_CHANGES
          );
      }

      deckConditionUpdated.value = false;

      surfaceConditionUpdated.value = false;

      conditionUpdated.value = false;
    };

    const updateMainRecordedDate = () => {
      structure.value.UserBrdg.MAIN_WS_THICK_DATE = new Date();
    };

    const updateApproachRecordedDate = () => {
      structure.value.UserBrdg.APPR_WS_THICK_DATE = new Date();
    };

    const updateDate = () => {
      structure.value.UserInsp.EST_CHLORIDE_CONTENT_DATE = new Date();
    };

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    const bridgeMedianTypes = computed(() => {
      return configStore.getReferenceValues(
        REFERENCE_TABLE.BRIDGE_MEDIAN,
        null,
        null,
        true
      );
    });

    const isRequiredByGroup = computed(() =>
      ["S1", "L1", "A1"].includes(structure.value?.Bridge?.REPORTGROUP?.trim())
    );

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.InspEvnt,
        structure.value.T_Insp_Comment,
        structure.value.UserInsp,
        structure.value.Bridge,
        structure.value.UserBrdg,
        structure.value.Roadway,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      conditionUpdated,
      updateDuration,
      surfaceConditionUpdated,
      deckConditionUpdated,
      panel,
      isEditable,
      structure,
      conditionRatings,
      deckSurfaceTypes,
      deckMembraneTypes,
      deckProtectionTypes,
      getHistDuration,
      commentsMaxLength,
      INSPECTION_COMMENTS,
      getFormattedDateStringNoTime,
      configStore,
      REFERENCE_TABLE,
      roadwayWidth,
      valid,
      validate,
      inspectionDeckForm,
      requiredRule,
      lessThanOneRule,
      greaterThanZeroRule,
      ROUNDING_FACTOR,
      PAIR_CODE,
      lessThanValueRule,
      DECK_MAX_VALUE,
      updateMainRecordedDate,
      updateApproachRecordedDate,
      updateDate,
      toggleAllPanels,
      bridgeMedianTypes,
      isRequiredByGroup,
    };
  },
  components: {
    CommentTypeTextField,
    LabelSelect,
    LabelText,
    LabelDatefield,
    ExpandCollapseToggle,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
</style>
